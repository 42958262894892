.instr-modal-body {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.modal-header h1 {
    font-size: 30px;
}

.instr-modal-body li {
    font-size: 15px;
    padding: 8px;
}

.instr-modal-body h3 {
    font-size: 20px;
    padding: 5px;
    border-bottom: 1px dotted white;
}
