.nav-container {
    color: #61677a;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 255px;
    box-shadow: 8px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 100;
}

.nav-header {
    font-size: 40px;
    font-family: "Times";
    margin-top: 20px;
    margin-left: -10px;
}

.nav-link {
    display: flex!important;
    flex-direction: row;
    margin: 15px 0;
    position: relative;
}

.nav-link a {
    font-family: "Helvetica";
    font-weight: 300;
    text-decoration: none;
    color: #c3c3c3;
}

.nav-link button {
    /* for the trash/logout buttons */
    font-family: "Helvetica";
    font-weight: 300;
    text-decoration: none;
    color: #c3c3c3;
    border: none;
    background: none;
    font-size: 100%;
}

.nav-link a:hover {
    color: #fafafa;
}

.selected {
    color: #fafafa;
}

.nav-links-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 40px;
}
.top-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px 0px;
    font-size: 20px;
}

.spacer {
    flex-grow: 1;
}

.bottom-links {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
}

.nav-link i {
    margin-right: 10px;
}

/* under Logout*/
.user-email {
    font-size: 10px;
    margin-left: 35px;
    margin-top: 5px;
}

.logout-container {
    flex-direction: column;
    align-items: flex-start;
}

.nav-logout {
    margin-left: 10px;
}

.nav-link.help {
    margin-left: 10px;
    margin-top: 25px;
}

.nav-aboutus {
    font-size: 13px;
    margin-left: 14px;
}

.nav-google-login-btn {
    margin-left: 10px;
}

.nav-google-login-btn i {
    margin-right: 5px; /* Adjust this value to add more or less space */
}

.nav-google-login-btn button {
    background: none;
    color: #61677a;
    font: inherit;
}

.nav-google-login-btn button:hover {
    color: #357ae8;
}
