/* Reset some basic elements */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
pre,
code,
address,
variable,
form,
fieldset,
blockquote {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-weight: normal;
}

/* Remember to define focus styles! */
:focus {
    outline: 0;
}

/* Reset blockquote */
blockquote,
q {
    quotes: none;
}

/* Reset images */
img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

/* Reset lists */
ul,
ol {
    list-style: none;
}

/* Reset tables */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Reset forms */
form {
    margin: 0;
}

/* Reset links */
a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    cursor: pointer;
}

/* Reset text-level semantics */
b,
strong {
    font-weight: bold;
}

cite,
em,
dfn,
i {
    font-style: italic;
    text-decoration: none;
}

/* Reset embedded content */
object,
embed {
    outline: none;
}

/* Reset various form elements */
input,
select,
textarea,
button {
    margin: 0;
    padding: 0;
    font-size: 100%;
    outline: none;
}

/* Reset body */
body {
    line-height: 1;
    background: white;
    color: black;
}

/* Reset #container and #content divs */
#container,
#content {
    margin: 0;
    padding: 0;
}

button {
    border: none;
    cursor: pointer;
}
