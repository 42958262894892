.emptyEmailTrash {
    width: 40px;
    height: 27px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-left: 35px;
    margin-top: 25px;
}

.trash-header {
    font-size: 27px;
    margin-left: 30px;
    color: #ccc;
}

.header-container {
    /* display: flex; */
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.restore-button {
    width: 70px;
    height: 35px;
    font-size: 14px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background-color: #ccc;
}

.pre-email-item:hover .restore-button {
    display: flex; /* or "block" depending on your layout */
}

.emailx-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 40px;
}

.trash.pre-email-item {
    margin-top: 0px;
}

.restore-button {
    position: absolute;
    margin-top: 100px;
}

/* Hide the restore button by default */
.restore-button {
    display: none;
}

.emptyEmailTrash {
    margin-left: 69px;
    margin-top: 40px;
}
