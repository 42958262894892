.container {
    display: grid;
    grid-template-columns: 1fr 2px 1fr;
    align-items: center;
    height: calc(100vh - 50px);
    background-color: #333;
}

/* Heading Styles */
.heading,
.heading-static,
.heading-dynamic {
    color: #fff;
    font-size: 4vw; /* Use viewport width for responsive font size */
}

.heading-static,
.heading-dynamic {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.heading-static {
    font-family: Times;
    left: 5%;
}

.heading-dynamic {
    left: 18%;
    font-size: 3.2vw;
}

/* Center Container Styles */
.center-container {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Helvetica, sans-serif;
    /* border: 1px solid black; */
}

.button-container {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.get-started {
    margin-left: -20px;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #fff;
}

.vertical-line {
    grid-column: 2;
    width: 2px;
    height: 50%;
    background-color: whitesmoke;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.neumorphic {
    border-radius: 1rem;
    background: #e0e0e0;
    width: 160px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    text-decoration: none;
    color: #333;
}

.neumorphic:first-child {
    margin-right: 50px; /* Add some space to the right of the first button */
}

.neumorphic a {
    text-decoration: none;
    color: #333;
}

/* Typing Animation Styles */
.typing {
    color: #fff;
    font-weight: normal;
}

.cursor {
    border-left: 2px solid #fff;
    width: 0;
    height: 1em;
    margin-left: 2px;
    animation: blink-animation 0.7s steps(1, start) infinite;
}

@keyframes blink-animation {
    50% {
        border-color: transparent;
    }
}

footer {
    color: #fff;
    text-align: center;
}

@media (max-width: 800px) {
    .heading-static,
    .heading-dynamic,
    .vertical-line {
        display: none;
    }
}

.custom-modal-content {
    max-width: 1000px !important;
    max-height: 650px !important;
}

.tutorialModal{
    position: fixed;
    top: 50%;      /* Vertical centering */
    left: 50%;     /* Horizontal centering */
    transform: translate(-50%, -50%);  /* Center the modal exactly in the middle of the screen */
    padding: 20px;          /* Add padding as needed */
    z-index: 1000;
    border: 1px solid white;
    background-color: #333;
}

.close-modal-btn{
text-align: center;
}

.center-btn {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
