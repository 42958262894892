.pre-email-item {
    width: 140px;
    height: 172px;
    border: 2px solid black;
    margin: 30px 25px;
    box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #2e2e2e;

    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    background-color: black;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding: 10px;
}

.email-subject {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allow text to wrap */
    max-width: 100%; /* Allow text to take full width of the container */
    line-height: 1.2em;
    height: 2.4em; /* height is 2x line-height, so two lines will show */
}

.email-body {
    margin-top: 4px;
    color: grey;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new-email-item {
    width: 140px;
    height: 172px;
    border: 2px solid black;
    margin: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #2e2e2e;
    cursor: pointer;
}

.delete-button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
}

.pre-email-item:hover .delete-button {
    display: block;
}

.delete-button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
}

.icon-light,
.icon-solid {
    color: #ffffff;
}

.icon-solid {
    display: none; /* Initially hidden */
}

.delete-button:hover .icon-light {
    display: none; /* Hide on hover */
}

.delete-button:hover .icon-solid {
    display: inline-block; /* Show on hover */
}
