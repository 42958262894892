/* div {
    border: 1px dotted white;
} */
.new-email-form-container {
    width: 33%;
    height: 35rem;
    box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #2e2e2e; /* neumorphism */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6%;
    margin-left: 5%;
    background-color: black;
    padding: 20px;
}

/* Input fields */
input,
textarea {
    background-color: black;
    color: white;
    border: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
}

.form-input {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    resize: none;
    border: 1px dotted white;
}

/* The form itself */
.new-email-form {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
}

.black-input-field {
    background-color: black !important;
    color: white !important;
    border: none;
}

.black-input-field::placeholder {
    color: #ccc;
}

.new_email_body {
    flex-grow: 1;
}

textarea {
    height: 100%;
}

.new-email-to input,
.new-email-subject input {
    background-color: black;
    color: white;
    border: none;
}

.dotted-line {
    border-bottom: 1px dotted white; /* Change 'white' to whatever color you prefer */
    margin: 10px 0; /* Add some margin to space it out */
}

.new-email-form-btn button {
    background: #2e2e2e; /* Same as your primary text color */
    border: 0.5px solid white; /* A subtle border */
    color: white; /* Text color */
    padding: 10px;
    font-family: Helvetica;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
}

.new-email-form-btn button:hover {
    background: white;
    color: #61677a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.exit-wrapper {
    display: flex;
    cursor: pointer;
    width: 16%;
    height: 10px;
}

.exit-icon {
    font-size: 24px;
    color: #61677a;
    padding-right: 10px;
    transition: color 0.3s ease;
}

.exit-text {
    font-family: Helvetica;
    color: #61677a;
    margin-top: 5px;
}

.exit-wrapper:hover .exit-icon,
.exit-wrapper:hover .exit-text {
    color: white;
}

.new-email-form-btn {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the end (right side) */
    width: 100%; /* Takes up full width */
}

.chat-message {
    position: relative;
    width: 33%; /* same width as the email form */
    min-height: 20rem; /* minimum height */
    max-height: 30rem;
    overflow-y: auto; /* make it scrollable */
    background-color: black; /* same background */
    color: white; /* same text color */
    padding: 20px; /* same padding */
    margin-top: 6%;
    margin-right: 100px;
    box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #2e2e2e; /* same shadow */
    font-family: "Courier";
}

.main-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.modal-content.expand {
    background-color: #2e2e2e;
}

.close-modal-btn {
    background: none;
    border: none;
    color: white;
    margin: 10px;
    cursor: pointer;
}

.form-modal-outer {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.19);
    border-radius: 300px;
}

.user-prefer-btn {
    position: relative;
    background: none;
    color: #ccc;
    border: none;
    cursor: pointer;
    left: 95%;
}

.user-prefer-btn:hover {
    color: white;
}

.error,
.body-error {
    color: #ccc;
    opacity: 0;
    transition: opacity 1s ease;
    font-size: 14px;
    margin-left: 60%;
}

.error.show,
.body-error.show {
    opacity: 1;
}

.copy-button {
    position: absolute;
    bottom: 2%;
    right: 2%;
    z-index: 1;
    padding: 10px;
    background: #2e2e2e;
    border: 0.5px solid white;
    color: #ccc;
    font-family: Helvetica;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
}

.loading-dots span {
    animation-name: loadingAnimation;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    display: inline-block;
}

.loading-dots span:nth-child(1) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes loadingAnimation {
    0% {
        transform: scaleY(0.4);
    }
    20%,
    40%,
    100% {
        transform: scaleY(1);
    }
    60% {
        transform: scaleY(0.4);
    }
}

.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-overlay.active {
    display: flex;
}

.modal-content {
    background: #2e2e2e;
    color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
.modal-body {
    margin-bottom: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-footer button {
    margin: 0 15px;
    padding: 5px;
}

.email-list-container {
    width: 95%;
    margin-left: 40px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.tut-btn{
    margin-left: 400px;
  }
