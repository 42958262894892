.team-members-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    /* border: 1px solid white; */
}

.member-box {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 400px;
    margin: 20px 0px;
    justify-content: space-around;
    align-items: center;
    color: #c3c3c3;
}

.member-pic {
    width: 250px;
    margin-bottom: 10px;
    object-fit: contain;
    box-shadow: 8px 8px 18px rgba(0, 0, 0, 0.5);
}

.links-container {
    display: flex;
    gap: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.links-container a {
    text-decoration: none;
    color: #61677a;
    font-size: 14px;
}

.bio-container {
    max-width: 250px;
    margin: 0 auto;
}
