body,
html,
#root {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}

.email-container {
    flex: 1; /* Takes up all remaining space */
    /* background-color: #323439;  */
    background-color: #2d2f33;
    z-index: 0;
    /* padding-top: 20px; */
}

.app-container,
.email-container {
    height: 100%;
}

.app-container {
    display: flex;
    z-index: 0;
    /* border: 1px dotted white; */
}
.email-container {
    flex-grow: 1;
}
