.search-bar-container {
    /* border: 1px dotted white; */
    width: 60%;
    margin-top: 20px;
    margin-left: 60px;
    position: relative;
}

.search-icon {
    position: absolute;
    top: 15px;
    right: 5px;
}
