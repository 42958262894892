.user-modal-content {
    width: 440px;
    height: 600px;
    display: flex;
    flex-direction: column;
    color: white;
}
.user-modal-header {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 25px;
    margin-left: 28px;
    border-bottom: 1px dotted white;
}

.user-modal-body {
    display: flex;
    flex-direction: column;
}

.user-modal-body-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 25px 20px;
    text-align: center;
    font-size: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.user-modal-submit-btn-container {
    border: none;
    color: white;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    margin-top: 70px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 8px;
}

.user-modal-submit-btn {
    width: 20%;
    height: 130%;
}

.horizontal-slider {
    width: 150px;
    height: 25px;
    bottom: 5px;
}

.temperature-thumb,
.presencePenalty-thumb,
.frequencyPenalty-thumb {
    width: 30px;
    height: 20px;
    background-color: grey;
    cursor: pointer;
    text-align: center;
}

.temperature-track,
.presencePenalty-track,
.frequencyPenalty-track {
    top: 10px;
    bottom: 0;
    background: grey;
    height: 5px;
}

.user-message-label {
    padding-top: 15px;
}

.user-message-input {
    padding-right: 6px;
}
.user-token-label {
    padding-top: 10px;
}

.presencePenalty-label {
    display: flex;
}

.max-tokens-input {
    background-color: #e0e0e0;
    color: black;
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 13px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.horizontal-slider {
    width: 150px;
    height: 25px;
    bottom: 5px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
}

.temperature-thumb,
.presencePenalty-thumb,
.frequencyPenalty-thumb {
    width: 30px;
    height: 20px;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    color: black;
}

.temperature-track,
.presencePenalty-track,
.frequencyPenalty-track {
    top: 10px;
    bottom: 0;
    background: #ccc;
    height: 5px;
}

.temperature-label,
.presencePenalty-label,
.frequencyPenalty-label {
    margin-top: 10px;
    font-size: 14px;
}

#preference-user-message {
    background-color: white;
    color: black;
}
